import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";
import this_ from '@/main'

export default class MacCms {
    constructor() {
        this.http = new HttpHandler();
    }

    /**
     * 获取配置
     * @returns {Promise<AxiosResponse<any>>}
     */
    getList(data) {
        let url = '/api/macCms/getList';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    /**
     * 修改配置
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    insData(data) {
        let url = '/api/macCms/insData';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    delData(id) {
        let url = `/api/macCms/delData?id=${id}`;
        return this.http.delete(url, null, HttpConstant.FORM_DATA_REQ);
    }

    updateData(data) {
        let url = `/api/macCms/updateData`;
        return this.http.put(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

}

